import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaExclamationTriangle } from 'react-icons/fa';
import {
  Auth0Provider,
  useAuth0,
  Auth0ContextInterface,
} from '@auth0/auth0-react';

import appInsights from 'configs/analyticsConfig';
import LoadingSpinner from 'components/data/LoadingSpinner/LoadingSpinner';
import ErrorAlert from 'components/error/ErrorAlert/ErrorAlert';
import { isProductionBuild } from 'globalUtils';

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

const isCypress =
  !!(window as { Cypress?: boolean }).Cypress && !isProductionBuild;

/**
 * A mocked version of auth0's getAccessTokenSilently function for when we are
 * running the app in cypress.
 */
function getAccessTokenSilentlyMock() {
  return new Promise((resolve) => {
    resolve('MOCK_BEARER_TOKEN');
  });
}

/**
 * This context serves two purposes:
 *
 * 1. Access auth0's context value and decorate the logout function when the app is
 * running regularly.
 * 2. Mock the auth0 context when the app is running in cypress.
 *
 * @note
 * Cypress does not support our auth0 PKCE flow because it requires redirecting away
 * from our app which cypress does not support. This is a well documented limitation
 * of cypress and there are a number of recommended strategies for working around this
 * limitation. Because we currently only plan to use cypress with mocked network requests,
 * we decided to completely mock our auth0 context when the app runs in cypress. If in the
 * future we decide to use cypress for true end to end tests that need to interact with our
 * graphql api, we can use the strategy documented by cypress on how to access a valid
 * bearer token from auth0 and update our getAccessTokenSilentlyMock function to return it.
 * https://docs.cypress.io/guides/testing-strategies/auth0-authentication#Auth0-Application-Setup.
 */
export function useAuthContext() {
  const realAuth0 = useAuth0();

  const mockAuth0 = {
    getAccessTokenSilently:
      getAccessTokenSilentlyMock as Auth0ContextInterface['getAccessTokenSilently'],
    logout: () => {},
  };

  return isCypress
    ? mockAuth0
    : {
        ...realAuth0,
        logout: () =>
          // These options ensure that we are always redirected to the same environment
          // we logged out from. If we didn't pass in these options, we would be redirected
          // to the production site even if we logged out from the develop site.
          realAuth0.logout({ returnTo: process.env.REACT_APP_HOST }),
      };
}

export default function AuthProvider({ children }: { children: JSX.Element }) {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();

  if (
    !REACT_APP_AUTH0_DOMAIN ||
    !REACT_APP_AUTH0_CLIENT_ID ||
    !REACT_APP_AUTH0_AUDIENCE
  ) {
    return (
      <ErrorAlert
        isFullScreen
        title="Unable to determine authorization environment"
        icon={<FaExclamationTriangle />}
        message="Unable to determine authorization environment. Please contact us. In the meantime, try refreshing the page."
      />
    );
  }

  return (
    <Auth0Provider
      // URL of the auth0 server
      domain={REACT_APP_AUTH0_DOMAIN}
      // This app's auth0 id
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      // Which app our auth token token is valid for, ensures our token isn't opaque
      audience={REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      useRefreshTokens
      // Redirects the user to their original url path after login redirect
      onRedirectCallback={(appState) => {
        if (!appState) {
          return;
        }
        // appState set on loginWithRedirect call below
        const { preLoginPathAndHash } = appState;
        const postLoginPathAndHash = pathname + hash;
        if (
          preLoginPathAndHash &&
          preLoginPathAndHash !== postLoginPathAndHash
        ) {
          navigate(preLoginPathAndHash);
        }
      }}
    >
      {/* Do not redirect the user to login when running in Cypress, see notes in useAuthContext above */}
      {isCypress ? children : <LoginRedirect>{children}</LoginRedirect>}
    </Auth0Provider>
  );
}

function LoginRedirect({ children }: { children: JSX.Element }) {
  const { loginWithRedirect, isAuthenticated, isLoading, error } = useAuth0();
  const { pathname, hash } = useLocation();

  // Redirect on app load
  useEffect(() => {
    // Need to guard the loading state because briefly after redirect isAuthenticated is
    // still false while isLoading is true
    if (!isAuthenticated && !isLoading) {
      void loginWithRedirect({
        appState: { preLoginPathAndHash: pathname + hash },
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, pathname, hash]);

  useEffect(() => {
    if (error) {
      appInsights.trackEvent({
        name: 'Authentication Error',
        properties: {
          error: error.message,
        },
      });
    }
  }, [error]);

  if (!isAuthenticated || isLoading) {
    return <LoadingSpinner isFullScreen />;
  }

  if (error) {
    return (
      <ErrorAlert
        isFullScreen
        title="Unable to authenticate"
        icon={<FaExclamationTriangle />}
        message="There was a problem communicating with the authentication server. Please contact us. In the meantime, try refreshing the page."
      />
    );
  }

  return children;
}
