import dayjs from 'dayjs';

export type DateSegment = 'minute' | 'hour' | 'day' | 'month' | 'year';

export interface DateTimeInfo {
  period: 'AM' | 'PM' | '';
  minute: string;
  hour: string;
  day: string;
  month: string;
  year: string;
}

/* 
  These values are used as representations by
  the ISO8601 time format, and therefore are
  used to parse out the string.
*/
export const parsingValues = {
  dateTime: 'T',
  time: ':',
  date: '-',
};

export const inputValidationRules = {
  month: {
    minLength: 1,
    maxLength: 2,
    minValue: 1,
    maxValue: 12,
  },
  day: {
    minLength: 1,
    maxLength: 2,
    minValue: 1,
    maxValue: 31,
  },
  year: { minLength: 4, maxLength: 4, minValue: 1900, maxValue: 2099 },
  hour: { minLength: 1, maxLength: 2, minValue: 0, maxValue: 23 },
  // Minutes are always padded, so the minLength is 2.
  minute: { minLength: 2, maxLength: 2, minValue: 0, maxValue: 59 },
};

export const spoofEvent = (value: string | null) => ({ target: { value } });

export const isAllZeroes = (value: string) => value.match(/^(0)\1*$/g) !== null;

export const stripLeadingZeroes = (value: string) => value.replace(/^0+/, '');

export const militaryToCivilianHour = (
  hour: string
): [string, DateTimeInfo['period']] => {
  // If the hour in invalid, do not try to calculate a period
  if (!isValid('hour', hour)) {
    return [hour, ''];
  }
  const hourNumber = Number(hour);
  const civilianHour =
    hourNumber === 0 ? 12 : hourNumber <= 12 ? hourNumber : hourNumber - 12;
  const calculatedPeriod = hourNumber < 12 ? 'AM' : 'PM';
  return [civilianHour.toString().padStart(2, '0'), calculatedPeriod];
};

export const civilianToMilitaryHour = (
  hourStr: string,
  period: DateTimeInfo['period']
) => {
  // Default the hour to 00 if AM is selected with no hour
  if (period === 'AM' && !hourStr) {
    return '00';
  }
  // Default the hour to 12 if PM is selected with no hour
  if (period === 'PM' && !hourStr) {
    return '12';
  }

  // If there is no hour or no period, preserve ''
  if (!hourStr) {
    return '';
  }

  const hourNumber = Number(hourStr);

  // Possibly update the hour value based on the hour and period
  if (period === 'AM' && hourNumber === 12) {
    return '00';
  }
  if (period === 'PM' && hourNumber < 12) {
    return `${hourNumber + 12}`;
  }

  // Otherwise the hour is already correct based on the period or it
  // is an invalid value, so preserve in both cases
  return hourStr;
};

export const isValueValid = (key: DateSegment, value: string) => {
  const { minValue, maxValue } = inputValidationRules[key];
  const valueNumber = Number(value);

  return valueNumber >= minValue && valueNumber <= maxValue;
};

const isValid = (key: DateSegment, value: string) => {
  const { minLength, maxLength, minValue, maxValue } =
    inputValidationRules[key];
  const valueNumber = Number(value);

  return (
    value.length >= minLength &&
    value.length <= maxLength &&
    valueNumber >= minValue &&
    valueNumber <= maxValue
  );
};

const getDateSegmentsFromIsoString = (isoString: string) => {
  const [date = '', time = ''] = isoString.split(parsingValues.dateTime);
  // Note, we don't have a seconds input in the BaseDateTimePicker but ISO strings do
  // contain a seconds value, so we always ignore seconds, and 0 them out whenever
  // we set the parent state. This means that whenever the user interacts with the
  // BaseDateTimePicker, any initial seconds values from the backend will be cleared.
  // This would only be an issue if the user interacted with the input and then set the
  // value back to its initial value. If this becomes a problem, see the git history for an
  // example of tracking the initial seconds with a ref as we used to do it but removed it
  // for simplicity.
  const [hour = '', minute = ''] = time.split(parsingValues.time);
  const [year = '', month = '', day = ''] = date.split(parsingValues.date);
  const mmddyyyy = `${month}${day}${year}`;

  return { minute, hour, day, month, year, mmddyyyy };
};
type RequestedDateTimeInfoFlags = 'DATE_ONLY' | 'TIME_ONLY' | 'DATE_AND_TIME';
type DateInfo = Pick<DateTimeInfo, 'month' | 'day' | 'year'>;
interface TimeInfo extends DateTimeInfo {
  month: '01';
  day: '01';
  year: '1900';
}

// This function parses an ISO string into our internal dateTimeValues object
// { month, day, year, hour, minute, period } since it's easier to work with
export function parseIsoToDateTimeValues(
  isoString: string,
  requestedInfo: 'DATE_ONLY'
): DateInfo;
export function parseIsoToDateTimeValues(
  isoString: string,
  requestedInfo: 'TIME_ONLY'
): TimeInfo;
export function parseIsoToDateTimeValues(
  isoString: string,
  requestedInfo: RequestedDateTimeInfoFlags
): DateTimeInfo;
export function parseIsoToDateTimeValues(
  isoString: string,
  requestedInfo: RequestedDateTimeInfoFlags
) {
  const {
    minute = '',
    hour = '',
    day = '',
    month = '',
    year = '',
  } = getDateSegmentsFromIsoString(isoString);

  const [civilianHour, period] = militaryToCivilianHour(hour);

  const parsedDate =
    requestedInfo === 'TIME_ONLY'
      ? { month: '01', day: '01', year: '1900' }
      : { month, day, year };

  const parsedTime =
    requestedInfo === 'DATE_ONLY'
      ? {}
      : {
          hour: civilianHour,
          minute,
          period,
        };

  return {
    ...parsedDate,
    ...parsedTime,
  };
}

export const parseIsoToDateTimeValuesWithMilitaryHour = (
  isoString: string
): Omit<DateTimeInfo, 'period'> & { militaryHour: string } => {
  const { minute, hour, day, month, year } =
    getDateSegmentsFromIsoString(isoString);
  return {
    minute,
    day,
    hour,
    month,
    year,
    militaryHour: hour,
  };
};

export const dateTimeValuesToIso = (
  dateTimeValues: DateTimeInfo,
  dateOnly?: boolean
) => {
  const { month, day, year } = dateTimeValues;
  const { hour, minute, period } = dateTimeValues;
  const date = `${year}-${month}-${day}`;

  if (dateOnly) {
    return date;
  }

  // The period is used to determine the military hour value in the iso string
  const calculatedHour = civilianToMilitaryHour(hour, period);
  const time = `${calculatedHour}:${minute}:00`;

  return `${date}T${time}`;
};

export const validateDay = (
  { day, month, year }: DateInfo,
  validator = isValid
) => {
  // A valid month and year are required to validate the day.
  if (!validator('month', month) || !validator('year', year)) {
    return true;
  }
  const yearNumber = Number(year);
  const isLeapYear =
    yearNumber % 100 === 0 ? yearNumber % 400 === 0 : yearNumber % 4 === 0;
  const febDays = isLeapYear ? 29 : 28;
  const daysPerMonth = [31, febDays, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  return Number(day) <= daysPerMonth[Number(month) - 1];
};

export const validateYearInput = (valueString: string) => {
  const valueNumber = Number(valueString);
  // Allow 2 digit years
  if (valueString.length === 2 && Number.isInteger(valueNumber)) {
    return true;
  }
  return isValid('year', valueString);
};

const validateDayInput = (valueString: string, dateTimeValues: DateInfo) =>
  // The day also needs to be validated against the month and year
  isValid('day', valueString) && validateDay(dateTimeValues);

export const validateInputValue = (
  type: DateSegment,
  valueString: string,
  dateTimeValues: DateInfo
) => {
  // Don't error when the input is blank
  if (!valueString) {
    return true;
  }
  if (type === 'year') {
    return validateYearInput(valueString);
  }
  if (type === 'day') {
    return validateDayInput(valueString, dateTimeValues);
  }
  return isValid(type, valueString);
};

export function getTimeOptionValues() {
  const result = [];
  let minutes = 0;
  // The below date is discarded when formatted and will not impact its parent date
  const dayJS = dayjs('01/01/1900 12:00 AM');

  for (let i = 1; i <= 24 * 4; i += 1) {
    result.push({
      id: i,
      name: dayJS.add(minutes, 'minute').format('h:mm A'),
    });
    minutes += 15;
  }

  return result;
}

export const timeFormat = 'h:mm A';

export const dateFormat = 'MM/DD/YYYY';

export const isoFormat = 'YYYY-MM-DDTHH:mm:ss';

export function timeStringWithArbitraryDate(timeStamp: string) {
  return `01/01/1900 ${timeStamp}`;
}
